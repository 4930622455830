.fees-container {
    font-family: 'OpenSansHebrew-Regular';
    line-height: 1.6;
    font-size: 20px;
}

.fee-section h3, .platform-fee h3 {
    font-weight: normal;
    margin-bottom: 15px;
}

.fee-details {
    list-style-type: disc;
    padding-right: 20px;
}

.fee-details > li {
    margin-bottom: 15px;
}

.fee-details ul {
    list-style-type: disc;
    padding-right: 20px;
    margin-top: 10px;
}

.fee-details strong {
    font-weight: bold;
}

u {
    text-decoration: underline;
}
